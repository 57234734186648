import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  RefinementList,
  Pagination,
} from "react-instantsearch-hooks-web";

const searchClient = algoliasearch(
  "NCBSIVAIRT",
  "c8459e75a31535b57388de83dad17e5b"
);

function Hit({ hit }) {
  return (
    // <article>
    //   <h1 className="text-2xl font-bold">
    //     <Highlight attribute="title" hit={hit} />
    //   </h1>
    //   <Highlight className="prose prose-gray" attribute="content" hit={hit} />
    // </article>
    <a
      href={`https://leetcode.com/problems/${hit.titleSlug}/`}
      target="_blank"
      class="block max-w p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
    >
      <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        <Highlight attribute="frontendQuestionId" hit={hit} />
        &nbsp;
        <Highlight attribute="title" hit={hit} />
      </h5>
      <p class="font-normal text-gray-700 dark:text-gray-400">
        <Highlight className="prose prose-gray" attribute="content" hit={hit} />
      </p>
    </a>
  );
}

function App() {
  return (
    <InstantSearch searchClient={searchClient} indexName="leetcode_problems">
      <div className="flex flex-row">
        <div className="basis-1/4">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            difficulty
          </h5>
          <RefinementList attribute="difficulty" />
          <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            tags
          </h5>
          <RefinementList attribute="topicTags" />
        </div>
        <div className="basis-3/4">
          <SearchBox />
          <Hits hitComponent={Hit} />
        </div>
      </div>

      <Pagination />
    </InstantSearch>
  );
}

export default App;
